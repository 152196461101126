import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useGetUser from '../../../../hooks/useGetUser';
import BackButton from '../../../../shared/components/BackButton/BackButton';
import { Button, Tabs } from '@solace-health/ui';
import * as S from './style';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { SchedulingDrawer } from './SchedulingDrawer/SchedulingDrawer';
import { LoadingSpinner } from '@solace-health/ui';
import PatientInfoSection from './PatientInfoSection/PatientInfoSection';
import useGetBookingsForPatient from '../../../../hooks/bookings/useGetBookings';
import InsuranceSection from './InsuranceSection/InsuranceSection';
import { SolaceAPI } from '../../../../utils/api';
import PatientDetails from './PatientDetails/PatientDetails';
import PatientHeader from './PatientHeader/PatientHeader';
import EligibilityCheckSection from './EligibilityCheckSection/EligibilityCheckSection';
import CareTeamSection from './CareTeamSection/CareTeamSection';
import CarePlanSection from './CarePlanSection/CarePlanSection';
import { Notes } from './Notes/Notes';
import VisitsAndEncounters from './VisitsAndEncounters/VisitsAndEncounter';
import { Booking } from '../../../../types/booking';
import { useQuery } from '../../../../hooks/useQuery';
import useGetUserCareTimeline from '../../../../hooks/useGetUserCareTimeline';
import { Referral } from '../../../../types/referrals';
import ArchiveAccordian from './PatientInfoSection/ArchiveAccordian';
import { hasTrait } from '../../../../utils/user';
import { Role, RoleName, TraitId, User } from '../../../../types/user';
import { CareRelationship } from '../../../../types/careRelationship';
import LovedOneFlag from './PatientInfoSection/LovedOneFlag';
import { LovedOneInfo } from './LovedOneInfo/LovedOneInfo';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { PatientDetailsDrawer } from './PatientDetailsDrawer/PatientDetailsDrawer';

type TABS = 'visits-encounters' | 'patient-info' | 'eligibility-checks' | 'insurance' | 'care-team' | 'care-plans' | 'notes';

const Patient = () => {
  const params = useParams<{ id: string }>();
  const query = useQuery();
  const openSchedule = query.get('schedule');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [activeKey, setActiveKey] = useState<TABS>('visits-encounters');

  const [inOmniscientMode, setInOmniscientMode] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);

  const [selectedAppointment, setSelectedAppointment] = useState<Booking | null>(null);

  const { userHasRole } = useCurrentUser<User & { roles: Role[] }>({
    include: ['roles'],
  });

  const [isCustomerExperience, isOutreach, isOmniscient] = [
    userHasRole(RoleName.CustomerExperience),
    userHasRole(RoleName.Outreach),
    userHasRole(RoleName.Omniscient),
  ];

  useEffect(() => {
    if (isOutreach) setActiveKey('notes');
  }, [isOutreach]);

  const {
    data: user,
    refresh,
    loginAs,
  } = useGetUser<{
    referral: Referral;
    client_care_relationships: (CareRelationship & { sharee: User })[];
    sharee_care_relationships: (CareRelationship & { client: User })[];
    identities: { provider: 'auth0' | 'google-oauth2' | 'facebook' }[];
  }>({
    id: params.id,
    includes: [
      'address',
      'client_bookings.advocate.traits',
      'identities',
      'patient_care_plans',
      'physician_visits',
      'referral.insurance_address',
      'referral.prospect',
      'referral.user.client_bookings',
      'referral.user.patient_care_plans',
      'referral.user.physician_visits',
      'traits',
      'user_notes',
      'advocate_relationships.advocate.traits',
      'sharee_care_relationships.client',
      'client_care_relationships.sharee',
    ],
  });

  useEffect(() => {
    if (openSchedule) {
      setDrawerOpen(true);
    }
    if (user) {
      if (hasTrait({ user, traitId: TraitId.CommunityHealthLovedOne })) {
        setActiveKey('patient-info');
      }
    }
  }, [openSchedule, user?.traits]);

  const { data: careTimelineData, refresh: refreshCareTimeline } = useGetUserCareTimeline({
    userId: isCustomerExperience ? params.id : undefined,
    includes: ['advocate.traits', 'client', 'video_call'],
  });

  const { refresh: refreshAppointments } = useGetBookingsForPatient({
    id: isCustomerExperience ? user?.id : undefined,
    includes: ['client.referral', 'advocate.traits', 'video_call'],
  });

  const onLoginAs = () => {
    if (!user?.sharetribe_uuid) return;
    loginAs({ id: user.sharetribe_uuid as string })
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        setInOmniscientMode(true);
      })
      .catch(() => {
        enqueueSnackbar('Failed to login as user. Reach out to engineering for help.', { variant: 'error' });
      });
  };

  if (!user) return <LoadingSpinner />;

  const onArchiveUser = async () => {
    if (!user?.id) return;

    await SolaceAPI.put({
      path: `/api/user/${user.id}`,
      body: { archived_dt: dayjs.utc().toISOString() },
    });

    refresh();

    enqueueSnackbar(`Successfully archived patient`, { variant: 'success' });
  };

  const onUnarchiveUser = async () => {
    if (!user?.id) return;

    await SolaceAPI.put({
      path: `/api/user/${user.id}`,
      body: { archived_dt: null },
    });

    refresh();

    enqueueSnackbar(`Successfully unarchived patient`, { variant: 'success' });
  };

  const isArchived = !!user?.archived_dt;

  const archivedAdvocatePatient = user.advocate_relationships?.find(
    (ap) => ap.archived_dt && hasTrait({ user: ap.advocate, traitId: TraitId.CommunityHealthAdvocate }),
  );

  const isLovedOneClient = user.client_care_relationships.length !== 0;

  const isLovedOne = hasTrait({ user, traitId: TraitId.CommunityHealthLovedOne });

  let tabs = [
    ...(isCustomerExperience
      ? [
          {
            label: 'Visits & Encounters',
            key: 'visits-encounters',
            children: (
              <VisitsAndEncounters patient={user} careTimelineData={careTimelineData} refresh={refreshCareTimeline} />
            ),
          },
        ]
      : []),
    ...(isCustomerExperience
      ? [
          {
            label: 'Patient Info',
            key: 'patient-info',
            children: <PatientInfoSection patient={user} refresh={refresh} />,
          },
        ]
      : []),
    ...(isCustomerExperience
      ? [
          {
            label: 'Insurance',
            key: 'insurance',
            children: <InsuranceSection patient={user} />,
          },
        ]
      : []),
    ...(isCustomerExperience
      ? [
          {
            label: 'Eligibility Checks',
            key: 'eligibility-checks',
            children: <EligibilityCheckSection patient={user} />,
          },
        ]
      : []),
    ...(isCustomerExperience
      ? [
          {
            label: 'Care Team',
            key: 'care-team',
            children: <CareTeamSection patient={user} />,
          },
        ]
      : []),
    ...(isCustomerExperience
      ? [
          {
            label: 'Care Plans',
            key: 'care-plans',
            children: <CarePlanSection patient={user} />,
          },
        ]
      : []),
    ...(isCustomerExperience || isOutreach
      ? [
          {
            label: 'Notes',
            key: 'notes',
            children: <Notes user={user} refresh={refresh} />,
          },
        ]
      : []),
    ...(isOmniscient
      ? [
          {
            label: (
              <Button.Link onClick={onLoginAs} color="#285E50">
                Log in as user
              </Button.Link>
            ),
            key: 'login-as-user',
          },
        ]
      : []),
    ...(isCustomerExperience
      ? [
          {
            label: isArchived ? (
              <Button.Link color="#285E50" onClick={onUnarchiveUser}>
                Unarchive User
              </Button.Link>
            ) : (
              <Button.Link color="#DA4D54" onClick={onArchiveUser}>
                Archive User
              </Button.Link>
            ),
            key: 'archive-user',
          },
        ]
      : []),
  ];

  const lovedOneTabs = isCustomerExperience
    ? [
        {
          label: 'Patient Info',
          key: 'patient-info',
          children: <PatientInfoSection patient={user} refresh={refresh} isLovedOne />,
        },
        {
          label: (
            <Button.Link onClick={onLoginAs} color="#285E50">
              Log in as user
            </Button.Link>
          ),
          key: 'login-as-user',
        },
      ]
    : [];

  if (isLovedOneClient) {
    const newTab = {
      label: 'Loved One',
      key: 'loved-one',
      children: <LovedOneInfo lovedOne={user?.client_care_relationships?.[0].sharee || undefined} />,
    };
    tabs = [...tabs.slice(0, 7), newTab, ...tabs.slice(7)];
    tabs.push();
  }

  return (
    <>
      <S.Container>
        {inOmniscientMode && (
          <S.OmniscientContainer>
            <h4 style={{ color: '#fff', marginTop: 0 }}>
              You are now in Omniscient Mode for {user.first_name} {user.last_name}.
            </h4>
            <a style={{ color: '#fff' }} href={`${process.env.REACT_APP_SOLACE_APP_URL}/login_as`}>
              Go to Dashboard
            </a>
          </S.OmniscientContainer>
        )}
        <BackButton onClick={() => navigate(-1)} label="Back" />

        <PatientHeader
          isCustomerExperience={isCustomerExperience}
          user={user}
          setDetailsDrawerOpen={setDetailsDrawerOpen}
          setSchedulingDrawerOpen={setDrawerOpen}
          refresh={refresh}
          isLovedOne={isLovedOne}
        />
        <PatientDetails user={user} />
        {archivedAdvocatePatient && <ArchiveAccordian advocatePatient={archivedAdvocatePatient} />}
        {isLovedOneClient && <LovedOneFlag user={user} />}
        <Tabs
          items={isLovedOne ? lovedOneTabs : tabs}
          defaultActiveKey={isCustomerExperience ? 'visits-encounters' : 'notes'}
          activeKey={activeKey}
          style={{ margin: '0', maxWidth: '100%' }}
          destroyInactiveTabPane
          onChange={(key) => {
            if (key === 'login-as-user' || key === 'archive-user') setActiveKey(activeKey);
            else setActiveKey(key as TABS);
          }}
        />
      </S.Container>

      {isCustomerExperience && (
        <SchedulingDrawer
          user={user}
          isOpen={drawerOpen}
          handleClose={() => {
            setDrawerOpen(false);
            setSelectedAppointment(null);
          }}
          refresh={() => {
            refresh();
            refreshAppointments();
            refreshCareTimeline();
          }}
          originalBooking={selectedAppointment}
        />
      )}

      {isCustomerExperience && <PatientDetailsDrawer open={detailsDrawerOpen} setOpen={setDetailsDrawerOpen} user={user} />}
    </>
  );
};

export default Patient;
