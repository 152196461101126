import { Flex, Button, Typography } from '@solace-health/ui';
import { CareRelationship } from '../../../../../types/careRelationship';
import { SolaceAPI } from '../../../../../utils/api';
import { SolaceApiError } from '../../../../../utils/errors';
import { copyToClipBoard } from '../../../../../utils/general';
import { useSnackbar } from 'notistack';
import { useAccountSetupUrl } from '../../../../../hooks/users/useAccountSetupUrl';
import { TraitId, User } from '../../../../../types/user';

type Props = {
  user: User & {
    client_care_relationships: (CareRelationship & { sharee: User })[];
    sharee_care_relationships: (CareRelationship & { client: User })[];
  };
};

export const ClaimAccountUrl = ({ user }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const lovedOnePatientRelationship = user?.sharee_care_relationships?.[0];
  const lovedOnePatient = lovedOnePatientRelationship?.client;

  const lovedOneRelationship = user?.client_care_relationships?.[0];
  const lovedOne = lovedOneRelationship?.sharee;

  const isLovedOne = user?.traits?.find((trait) => trait.id === TraitId.CommunityHealthLovedOne);
  const isSelf = !isLovedOne;

  const { data, isLoading } = useAccountSetupUrl({ userId: user.id });
  const url = data?.url;

  const handleCopyToClipboard = (text: string) => {
    copyToClipBoard({ text });
    enqueueSnackbar(`Successfully copied ${text} to clipboard`, { variant: 'success' });
  };

  const createClaimAccountUrl = async () => {
    try {
      const response = await SolaceAPI.post<{ url: string }>({
        path: `/api/account_setup_url`,
        body: {
          patient_id: isLovedOne ? lovedOnePatient.id : user.id,
          care_relationship_sharee_id: isLovedOne ? user.id : null,
        },
      });
      handleCopyToClipboard(response.data.url);
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`${error.message} [${error.requestId}]`, { variant: 'error' });
    }
  };

  if (isLoading) return null;

  if (url) {
    return (
      <Flex vertical gap=".5rem">
        <Button.Link onClick={() => handleCopyToClipboard(url)}>Copy claim account url</Button.Link>
      </Flex>
    );
  }

  if (isSelf && !lovedOne?.active) {
    return <Typography.Body>No claim account url for user because the user hasn't booked their first call.</Typography.Body>;
  }

  return <Button.Link onClick={createClaimAccountUrl}>Copy claim account url</Button.Link>;
};
