import { Button, Flex, Icons, Segmented, Typography } from '@solace-health/ui';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminTable } from '../../../../components/shared/Table';
import StatusPill from '../../../../components/sharedStatusPill/StatusPill';
import useGetUser, { GetUserResponse } from '../../../../hooks/useGetUser';
import { CareTimelineTypes } from '../../../../hooks/useGetUserCareTimeline';
import { useGetVisits } from '../../../../hooks/useGetVisits';
import { formatSortStrings } from '../../../../shared/utils/table-utils';
import { Booking, BookingState, CallTypeRequested } from '../../../../types/booking';
import { UserTimeZone } from '../../../../types/user';
import { timeZoneAbbr } from '../../../../utils/general';
import { SchedulingDrawer } from '../PatientPage/SchedulingDrawer/SchedulingDrawer';
import VisitsAndEncounterDrawer from '../PatientPage/VisitsAndEncounters/VisitsAndEncounterDrawer/VisitsAndEncounterDrawer';
import { AdvocateTypeFilter, TableBooking, UseGetVisitsFilter } from './types';
import { getBookingStates, getTraitsForAdvocateType, getVisitType } from './utils';

dayjs.extend(timezone);

const statusPillOptions = (state: BookingState) => {
  switch (state) {
    case BookingState.Completed:
      return {
        borderColor: 'var(--border-color-dark-green, #285E50)',
        backgroundColor: 'var(--green-100, #F4F8F7)',
        text: 'Occurred',
      };
    case BookingState.ClientNoShow:
      return {
        borderColor: 'var(--border-color-danger, #DA4D54)',
        backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
        text: 'Patient no-show',
      };
    case BookingState.Cancelled:
      return {
        borderColor: 'var(--border-color-danger, #DA4D54)',
        backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
        text: 'Cancelled',
      };
    case BookingState.Rescheduled:
      return {
        borderColor: 'var(--border-color-dark, #555)',
        backgroundColor: 'var(--grey-100, #EEE)',
        text: 'Rescheduled',
      };
    case BookingState.Accepted:
      return {
        borderColor: 'var(--border-color-warning, #D7A13B)',
        backgroundColor: 'var(--gold-100, #FFF7E9)',
        text: 'Scheduled',
      };
    default:
      return {
        borderColor: '',
        backgroundColor: '',
        text: '',
      };
  }
};

export default function VisitsPage() {
  const history = useNavigate();
  const activeUsersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [activeAppointment, setActiveAppointment] = useState<Booking | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [schedulingDrawerOpen, setSchedulingDrawerOpen] = useState(false);

  const [visitsFilter, setVisitsFilter] = useState<UseGetVisitsFilter>({
    traitIds: getTraitsForAdvocateType(AdvocateTypeFilter.ClinicianVisits),
  });

  const {
    data: visitsData,
    isLoading: loadingVisits,
    mutate: refreshVisits,
    pagination,
    setSearch,
    setQueryParams,
  } = useGetVisits({
    filter: {
      ...(visitsFilter.traitIds ? { 'advocate.traits.id': visitsFilter.traitIds } : {}),
      ...(visitsFilter.bookingStateFilters ? { state: visitsFilter.bookingStateFilters } : {}),
    },
    include: ['advocate.traits', 'client', 'video_call'],
  });

  const { data: user } = useGetUser({
    id: activeAppointment?.client_id,
    includes: [
      'address',
      'client_bookings.advocate.traits',
      'identities',
      'patient_care_plans',
      'physician_visits',
      'referral.prospect',
      'referral.user.client_bookings',
      'referral.user.patient_care_plans',
      'referral.user.physician_visits',
      'traits',
    ],
  });

  const formattedData: TableBooking[] = visitsData.map((visit) => ({
    ...visit,
    type: getVisitType(visit),
    status:
      dayjs(visit.end).isBefore(dayjs()) && visit.state === BookingState.Accepted ? BookingState.Completed : visit.state,
    key: visit.id,
  }));

  const columns = [
    {
      title: 'Date',
      dataIndex: 'start',
      render: (start: string) => dayjs(start).tz(activeUsersTimeZone).format('MM/DD/YYYY'),
      sorter: true,
    },
    {
      title: 'Start Time',
      dataIndex: 'start',
      render: (start: string) =>
        `${dayjs(start).tz(activeUsersTimeZone).format('h:mma')} ${timeZoneAbbr(activeUsersTimeZone as UserTimeZone)}`,
    },
    {
      title: 'Patient Name',
      dataIndex: ['client', 'fullName'],
      render: (name: string, record: TableBooking) => (
        <Button.Link onClick={() => history(`/patients/${record.client_id}`)} color="#285E50">
          {name}
        </Button.Link>
      ),
      sorter: true,
    },
    {
      title: 'Advocate',
      dataIndex: ['advocate', 'fullName'],
      render: (_: string, record: TableBooking) => (
        <Button.Link onClick={() => history(`/patients/${record.advocate_id}`)} color="#285E50">
          {record.advocate?.fullName}
        </Button.Link>
      ),
      sorter: true,
    },

    {
      title: 'Type',
      dataIndex: 'type',
      render: (type: string, record: TableBooking) => {
        return (
          <Flex align="center" gap=".5rem">
            {record.call_type_requested === CallTypeRequested.Phone ? <Icons.Phone /> : <Icons.Video />}
            <Typography.Body>{type}</Typography.Body>
          </Flex>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: BookingState) => {
        const { borderColor, backgroundColor, text } = statusPillOptions(status);
        if (!text) return 'n/a';
        return (
          <StatusPill
            borderColor={borderColor}
            backgroundColor={backgroundColor}
            strikeThrough={status === BookingState.Rescheduled}
          >
            {text}
          </StatusPill>
        );
      },
      filters: [
        { text: 'Scheduled', value: BookingState.Accepted },
        { text: 'Rescheduled', value: BookingState.Rescheduled },
        { text: 'Completed', value: BookingState.Completed },
        { text: 'Patient no-show', value: BookingState.ClientNoShow },
      ],
    },
    {
      title: '',
      dataIndex: 'key',
      render: () => (
        <Button.Unstyled>
          <Icons.Caret direction="right" />
        </Button.Unstyled>
      ),
    },
  ];

  const handleSearch = debounce((value: string) => {
    setSearch(value);
  }, 300);

  const handleClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Flex vertical gap="1rem">
        <Typography.Display>Clinician Visits</Typography.Display>
        <div>
          <Segmented
            options={[
              { label: AdvocateTypeFilter.ClinicianVisits, value: AdvocateTypeFilter.ClinicianVisits },
              { label: AdvocateTypeFilter.AdvocateVisits, value: AdvocateTypeFilter.AdvocateVisits },
              { label: AdvocateTypeFilter.AllVisits, value: AdvocateTypeFilter.AllVisits },
            ]}
            onChange={(e) => {
              setVisitsFilter((prevState) => ({
                ...prevState,
                traitIds: getTraitsForAdvocateType(e as AdvocateTypeFilter),
              }));
              setQueryParams({ page: 1 });
            }}
          />
        </div>
        <AdminTable
          columns={columns}
          data={formattedData}
          onRowClick={(record: TableBooking) => {
            setActiveAppointment(record);
            setDrawerOpen(true);
          }}
          onChange={(params) => {
            const { filter, page, sort } = params;

            const newVisitsFilter: UseGetVisitsFilter = {
              bookingStateFilters: filter?.status ? getBookingStates(filter.status as string[]) : undefined,
            };

            setVisitsFilter((prevState) => ({ ...prevState, ...newVisitsFilter }));

            const newQueryParams = {
              page,
              sort: formatSortStrings(!sort?.length ? ['-start'] : sort, {
                'client,fullName': ['client.first_name', 'client.last_name'],
                'advocate,fullName': ['advocate.first_name', 'advocate.last_name'],
              }),
            };

            setQueryParams(newQueryParams);
          }}
          showPagination
          pagination={pagination}
          loading={loadingVisits}
          onTextFilter={handleSearch}
        />
      </Flex>

      <VisitsAndEncounterDrawer
        isOpen={drawerOpen}
        data={{ type: CareTimelineTypes.Visit, data: activeAppointment ?? ({} as Booking) }}
        onClose={handleClose}
        refresh={refreshVisits}
        clearSelectedData={() => setActiveAppointment(null)}
        setSchedulingDrawerOpen={setSchedulingDrawerOpen}
      />

      <SchedulingDrawer
        user={user ?? ({} as GetUserResponse)}
        isOpen={schedulingDrawerOpen}
        handleClose={() => {
          setSchedulingDrawerOpen(false);
          setActiveAppointment(null);
        }}
        refresh={refreshVisits}
        originalBooking={activeAppointment}
      />
    </>
  );
}
