import useSWR from 'swr';
import fetcher from './fetcher';
import qs from 'qs';
import { hasRole } from '../AppAuthenticated';
import { Role, User } from '../types/user';

type Props = {
  include?: ('roles' | 'invitee_invitations' | 'stripe_account' | 'invitee_invitations.inviter')[];
};

type UserWithRoles = User & { roles: Role[] };

const useCurrentUser = <T = unknown>({ include = [] }: Props) => {
  const urlParams = qs.stringify({ include });
  const { data, error } = useSWR<T>(`/v1/api/current_user?${urlParams}`, fetcher);

  const userHasRole = (roleName: Role['name']) => {
    const user = data as unknown as UserWithRoles;
    if (user && !include.includes('roles')) throw new Error('Roles must be included to use userHasRole');

    return hasRole({ user, roles: [roleName] });
  };

  return {
    data,
    loading: data === undefined,
    error,
    userHasRole,
  };
};

export default useCurrentUser;
