import { Collapse, Size, Typography } from '@solace-health/ui';
import { ListingItem } from './ListingItem';
import { CommunityHealthListing } from '../../../../../hooks/useCHWAvailability';

type Props = {
  firstAvailableListing: CommunityHealthListing;
  selectedListingId: string | null;
  setSelectedListingId: (id: string | null) => void;
  listings: CommunityHealthListing[];
  showPhysicianAvailability: boolean;
};

export const AvailableAppointments = ({
  firstAvailableListing,
  selectedListingId,
  setSelectedListingId,
  listings,
  showPhysicianAvailability,
}: Props) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Typography.Header size={Size.XS}>
          First Available {showPhysicianAvailability ? 'Physician' : 'CHW'}
        </Typography.Header>
        <ListingItem
          selected={firstAvailableListing.id === selectedListingId}
          onClick={() => setSelectedListingId(firstAvailableListing.id || null)}
          listing={firstAvailableListing}
        />
      </div>
      {listings.length > 0 && (
        <Collapse
          items={[
            {
              key: 'all-available-chws',
              label: (
                <Typography.Header size={Size.XS}>
                  View All Available {showPhysicianAvailability ? 'Physicians' : 'Advocates'}
                </Typography.Header>
              ),
              children: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  {listings.map((listing) => (
                    <ListingItem
                      selected={listing.id === selectedListingId}
                      onClick={() => setSelectedListingId(listing.id)}
                      key={listing.id}
                      listing={listing}
                    />
                  ))}
                </div>
              ),
            },
          ]}
        />
      )}
    </>
  );
};
