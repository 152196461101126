import { Button, Divider, Flex, Form, FormatType, Icons, Typography, UseFormReturn, styled } from '@solace-health/ui';
import { useEffect, useState } from 'react';
import usePatient, { UpsertPatientDto } from '../../../hooks/usePatient';
import { useSnackbar } from 'notistack';
import { GetUserResponse } from '../../../hooks/useGetUser';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { HereFor, InsuranceProgramId } from '../../../types/prospect';
import usePayors from '../../../hooks/usePayors';
import { InsuranceCompanyId } from '../../../types/payor';
import { BadEligibilityCheckRequestInvalidField, SolaceApiError } from '../../../utils/errors';
import { BookingHelpRequest, BookingHelpRequestType } from '../../../hooks/prospects/useGetBookingHelpRequests';
import dayjs from 'dayjs';
import { phoneNumberFormat } from '../../../utils/general';
import * as S from './style';
import { LovedOneInfoForm } from './LovedOneInfoForm';

type Props = {
  form: UseFormReturn<UpsertPatientDto>;
  onSubmit: (patientId: string) => void;
  currentPatient: GetUserResponse | null;
  prospectId?: string;
};

export const insuranceProgramOptions = [
  {
    label: 'Medicare',
    value: InsuranceProgramId.Medicare,
  },
];

export const sexOptions = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
];

const invalidFieldNames = {
  [BadEligibilityCheckRequestInvalidField.Dob]: ['dob'],
  [BadEligibilityCheckRequestInvalidField.MemberId]: ['member_id'],
  [BadEligibilityCheckRequestInvalidField.Name]: ['first_name', 'last_name'],
  [BadEligibilityCheckRequestInvalidField.NameOrDob]: ['first_name', 'last_name', 'dob'],
} as const;

const FormContainer = styled(Form.Container)`
  margin: 10px 0;
  overflow: hidden;
`;

const ConfirmationText = styled(Typography.Header)`
  text-align: center;
  color: #285e50;
`;

const PatientInfoForm = ({ form, onSubmit, currentPatient = null, prospectId: queryParamProspectId }: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { checkForPotentialBookingHelpRequests, createPatient, updatePatient, checkPatientInsuranceEligibility } =
    usePatient();
  const { payors } = usePayors({ include: ['insurance_company'] });

  const [insuranceConfirmed, setInsuranceConfirmed] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [potentialBookingHelpRequests, setPotentialBookingHelpRequests] = useState<BookingHelpRequest[]>([]);

  const {
    formState: { errors },
    formState,
    watch,
    setValue,
    setError,
  } = form;

  const hereFor = watch('referred_by');
  const insuranceProgramId = watch('payor.insurance_program_id');
  const insuranceCompanyId = watch('payor.insurance_company_id');
  const state = watch('address.state');
  const city = watch('address.city');
  const zip = watch('address.zip');

  const hereForLovedOne = hereFor === HereFor.LovedOne;
  const hereForSelf = hereFor === HereFor.Self;
  const cityStateZip = `${state && city ? `${city}, ` : city} ${state} ${zip}`.trim();

  useEffect(() => {
    if (formState.isDirty) {
      setValue('payor.insurance_company_id', '');
    }
  }, [state]);

  const insuranceCompanyOptions =
    insuranceProgramId && state
      ? payors.reduce<{ label: string; value: InsuranceCompanyId }[]>((accum, payor) => {
          if (payor.insurance_company && payor.insurance_program_id === insuranceProgramId && payor.state === state) {
            accum.push({
              label: payor.insurance_company.name,
              value: payor.insurance_company_id as InsuranceCompanyId,
            });
          }

          return accum;
        }, [])
      : [];

  const hereForOptions = [
    { label: 'Self', value: HereFor.Self },
    { label: 'Loved One', value: HereFor.LovedOne },
  ];

  const hasLocationError = !!(get(errors, ['address', 'street']) || get(errors, ['address', 'state']));

  const upsertPatient = async (data: UpsertPatientDto): Promise<any> => {
    let patient;
    if (data.id) {
      patient = await updatePatient(data.id, data);
    } else {
      const bookingHelpRequests = await checkForPotentialBookingHelpRequests({ params: data });

      if (potentialBookingHelpRequests.length === 0 && bookingHelpRequests.length > 0) {
        setPotentialBookingHelpRequests(bookingHelpRequests);
        return null;
      }

      patient = await createPatient({ body: data });
    }

    return patient;
  };

  const onHandleSubmit = async ({ prospectId }: { prospectId?: string }) => {
    const isValid = await form.trigger();
    if (!isValid) return;

    setSaving(true);
    const data = form.getValues() as UpsertPatientDto;

    try {
      const patient = await upsertPatient({
        ...data,
        phone: data.phone,
        email: data.email,
        payor: {
          insurance_company_id: data.payor.insurance_company_id || null, // convert empty string to null
          insurance_program_id: data.payor.insurance_program_id,
        },
        id: currentPatient?.id,
        member_id: data.member_id?.replaceAll('-', ''),
        prospect_id: prospectId || queryParamProspectId,
      });

      if (!patient) return;

      enqueueSnackbar(`Patient ${currentPatient?.id ? 'Updated' : 'Created'}`, { variant: 'success' });
      onSubmit(patient.id);

      navigate(`/patients/${patient.id}`);
    } finally {
      setSaving(false);
    }
  };

  const onHandleSaveAndCheck = async ({ prospectId }: { prospectId?: string }) => {
    const isValid = await form.trigger();
    if (!isValid) return;

    setSubmitting(true);
    const data = form.getValues() as UpsertPatientDto;
    try {
      const patient = await upsertPatient({
        ...data,
        phone: data.phone,
        email: data.email,
        payor: {
          insurance_company_id: data.payor.insurance_company_id || null, // convert empty string to null
          insurance_program_id: data.payor.insurance_program_id,
        },
        id: currentPatient?.id,
        member_id: data.member_id?.replaceAll('-', ''),
        prospect_id: prospectId || queryParamProspectId,
      });

      if (!patient) return;

      await checkPatientInsuranceEligibility({ id: patient.id });
      enqueueSnackbar(`Check passed!`, { variant: 'success' });
      onSubmit(patient.id);
      setInsuranceConfirmed(true);

      navigate(`/patients/${patient.id}`);
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Check failed, please confirm details and resubmit - ${error.message}`, { variant: 'error' });
      if (error.invalidField) {
        const formFieldNames = invalidFieldNames[error.invalidField];
        formFieldNames?.forEach((fieldName, idx) => setError(fieldName, { type: 'value', message: error.message }));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onHandleSchedule = () => currentPatient && navigate(`/patients/${currentPatient.id}?schedule=true`);

  return (
    <FormContainer formMethods={form} onSubmit={onHandleSubmit}>
      <Typography.Header>{currentPatient ? 'Update' : 'Add'} Patient Info</Typography.Header>
      <Divider />

      <Flex vertical gap={24}>
        {!currentPatient && (
          <Form.Select label="Here For" name="referred_by" formOptions={{ required: true }} options={hereForOptions} />
        )}
        <Typography.Header>Enter Patient Information</Typography.Header>

        <Flex gap={8}>
          <Form.Text name="first_name" label="First Name" formOptions={{ required: true }} />
          <Form.Text name="last_name" label="Last Name" formOptions={{ required: true }} />
        </Flex>

        <Form.DateDropdown
          name="dob"
          label="Date of Birth"
          formOptions={{ required: { message: 'This field is required', value: true } }}
          containerStyle={{ marginBottom: 0 }}
        />
        <Form.RadioGroup name="sex" label={`Sex`} options={sexOptions} formOptions={{ required: true }} />
        <Form.Text
          name={'email'}
          formOptions={{ required: hereForSelf }}
          pattern={FormatType.Email}
          label={'Email'}
          disabled={!!currentPatient}
          placeholder="Email address"
        />
        <Form.Text
          label={'Phone Number'}
          name={'phone'}
          formOptions={{ required: true }}
          format={FormatType.Phone}
          pattern={FormatType.Phone}
          placeholder="(123) 456-7890"
        />
        <Flex gap="1rem">
          <div style={{ width: '100%' }}>
            <Form.LocationSelect
              api_key={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
              label="Address"
              locationType="full"
              streetFieldName="address.street"
              cityFieldName="address.city"
              stateFieldName="address.state"
              zipFieldName="address.zip"
              formOptions={{ required: true }}
              errorMessage="Please select a city and state from the dropdown"
              hasError={hasLocationError}
            />
          </div>
          {(city || state) && <div style={{ marginTop: '3rem', width: '100%' }}>{cityStateZip}</div>}
        </Flex>

        {hereForLovedOne && (
          <>
            <Divider style={{ margin: 0 }} />
            <Typography.Header>
              {currentPatient ? 'Update' : 'Enter'} {hereForLovedOne && 'Loved One Information'}
            </Typography.Header>
            <LovedOneInfoForm />
          </>
        )}

        <Divider style={{ margin: 0 }} />

        <Typography.Header>Add {hereForLovedOne && 'Patient'} Insurance</Typography.Header>

        {/* <Form.SelectMenu
          // Can start displaying this once we add more programs besides medicare/medicare advantage
          options={insuranceProgramOptions} 
          name="payor.insurance_program_id"
          label="Insurance Program"
          formOptions={{ required: true }}
        /> */}
        <Form.SelectMenu
          options={insuranceCompanyOptions}
          name="payor.insurance_company_id"
          label="Medicare Advantage Payor"
          formOptions={{ required: false }}
          details="Options will be filtered by patient's state"
          onChange={(value) => {
            if (!value) setValue('payor.insurance_company_id', '');
          }}
        />

        <Form.Text
          name="member_id"
          label={insuranceCompanyId ? 'Medicare Advantage number' : 'Medicare Number'}
          placeholder={insuranceCompanyId ? 'Medicare Advantage number' : 'Medicare Number'}
          details="The format will change based on medicare vs medicare advantage"
          format={insuranceCompanyId ? undefined : FormatType.MBI}
        />

        {potentialBookingHelpRequests.length > 0 && (
          <Flex vertical gap="1rem">
            <Typography.Header>Potential Matches</Typography.Header>
            <Typography.Body>
              The following prospects/referrals were found that may be linked to this patient.
            </Typography.Body>
            {potentialBookingHelpRequests.map(({ type, prospect }) => {
              return (
                <S.PatientCard align="center" justify="space-between">
                  <Flex vertical gap="1rem">
                    <Typography.Body bold>
                      {type === BookingHelpRequestType.PatientReferral ? 'Patient Referral' : 'Prospect'}
                    </Typography.Body>
                    <Flex vertical gap=".25rem">
                      <Typography.Body>
                        {prospect.first_name} {prospect.last_name}
                      </Typography.Body>
                      <Typography.Body>{phoneNumberFormat(prospect.phone)}</Typography.Body>
                      <Typography.Body>
                        {prospect.payload.patient_dob && dayjs.utc(prospect.payload.patient_dob).format('MM/DD/YYYY')}
                      </Typography.Body>
                    </Flex>
                  </Flex>
                  <Flex vertical align="flex-end" gap="1rem">
                    <Button.Link
                      onClick={() => onHandleSubmit({ prospectId: prospect.id })}
                      isSubmitting={isSaving}
                      disabled={isSubmitting}
                    >
                      Merge Patient
                    </Button.Link>
                    <Button.Link
                      onClick={() => onHandleSaveAndCheck({ prospectId: prospect.id })}
                      isSubmitting={isSubmitting}
                      disabled={isSaving}
                    >
                      <Flex gap={14} align="center">
                        Merge & Check Eligibility
                      </Flex>
                    </Button.Link>
                  </Flex>
                </S.PatientCard>
              );
            })}
          </Flex>
        )}

        {!insuranceConfirmed ? (
          <Flex gap={12} style={{ marginTop: 20 }}>
            <Button.Outline
              style={{ maxWidth: 160 }}
              onClick={onHandleSubmit}
              isSubmitting={isSaving}
              disabled={isSubmitting}
            >
              {potentialBookingHelpRequests.length === 0 ? 'Save Patient' : 'Create New'}
            </Button.Outline>
            <Button.Primary onClick={onHandleSaveAndCheck} isSubmitting={isSubmitting} disabled={isSaving}>
              <Flex gap={14} align="center">
                {potentialBookingHelpRequests.length === 0 ? (
                  <>
                    Save & Check Eligibility <Icons.Arrow color="#fff" />
                  </>
                ) : (
                  <>
                    Create New & Check Eligibility <Icons.Arrow color="#fff" />
                  </>
                )}
              </Flex>
            </Button.Primary>
          </Flex>
        ) : (
          <ConfirmationText>Insurance confirmed!</ConfirmationText>
        )}
        {currentPatient && <Button.Primary onClick={onHandleSchedule}>Schedule Patient</Button.Primary>}
      </Flex>
    </FormContainer>
  );
};

export default PatientInfoForm;
